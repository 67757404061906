html.ios {
    --ion-default-font: "Inter", "Helvetica Neue", sans-serif;
    color: var(--ion-color-dark);
}

html.md {
    --ion-default-font: "Inter", "Helvetica Neue", sans-serif;
    color: var(--ion-color-dark);
}

html {
    --ion-font-family: var(--ion-default-font);
    color: var(--ion-color-dark);
}

h1 {
    font-weight: bold;
    color: var(--ion-color-dark);
}

h2 {
    font-weight: bold;
    color: var(--ion-color-dark);
}

h3 {
    font-weight: bold;
    color: var(--ion-color-dark);
}

h4 {
    font-weight: bold;
    color: var(--ion-color-dark);

    &.uppercase {
        text-transform: uppercase;
    }
}

h5 {
    font-weight: bold;
    color: var(--ion-color-dark);

    &.uppercase {
        text-transform: uppercase;
    }
}

h6 {
    font-weight: bold;
    font-size: 0.9em;
    color: var(--ion-color-dark);

    &.uppercase {
        text-transform: uppercase;
    }
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../assets/fonts/material/Material-Icons.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* inter-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/inter/inter-v12-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/inter/inter-v12-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
