/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import 'bootstrap/scss/bootstrap';

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
//@import '~@ionic/angular/css/float-elements.css';
//@import '~@ionic/angular/css/text-alignment.css';
//@import '~@ionic/angular/css/text-transformation.css';
//@import '~@ionic/angular/css/flex-utils.css';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '@beyonk/gdpr-cookie-consent-banner/dist/style.css';
@import "theme/material";
@import "theme/typography";

// cookie banner - start
.cookieConsentToggle {
    bottom: 75px;
}

.cookieConsentWrapper {
    font-size: 12px;
    padding: 10px;

    .cookieConsent__Button {
        padding: 10px;
    }

    .cookieConsent__Right {
        :last-child {
            background: rgb(235, 145, 1);
            font-weight: bold;
        }
    }

    // mobile
    @media only screen and (max-width: 900px) {
        .cookieConsent__Right {
            margin-top: 10px;

            .cookieConsent__Button {
                margin: 0 10px;
                font-size: 14px;
            }
        }
    }
}

ion-tab-bar {
    height: 64px;
}

// cookie banner - end

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#background-content {
    background-color: #f9f9f9;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.container {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.small-text {
    font-size: 0.8em;
    font-style: italic;
}

.bold-text {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin {
    margin-top: var(--wew-spacing-150);
    margin-bottom: var(--wew-spacing-150);
}

h1 {
    margin-top: 24px;
    margin-bottom: 20px;
}

.separator {
    width: 100%;
    margin-top: var(--wew-spacing-150);
    margin-bottom: var(--wew-spacing-150);
    flex-direction: column;
}

hr.separator {
    width: 100%;
    border-top: 1px solid #9f9f9f;
}

.image-crop {
    margin-bottom: 0.5rem;
    border-radius: 4px;
    max-height: 400px;
    overflow: hidden;

    > img {
        object-fit: cover;
        max-height: inherit;
        width: 100%;
        height: 100%;
    }
}

.description {
    white-space: break-spaces;
}
